<form *ngIf="!submitSuccess; else requestSuccess" (ngSubmit)="submitForm()" #supportRequestForm="ngForm">

<div class="modal-header">
    <h6 class="modal-title">Request a Health System?</h6>
    <button type="button" class="btn close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body">

    Sharing your email and health system helps us prioritize our EHR integrations.

    <div class="col-12">
      <p class="mg-t-10 mg-b-5">Your email address<span ngbTooltip="required" class="text-danger">*</span></p>
      <input
        class="form-control" placeholder="my@emailaddress.com"
        [(ngModel)]="formRequestHealthSystem.email" name="email" #email="ngModel" required email
      >

      <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
        <div *ngIf="email.errors?.['required']">
          Email is required.
        </div>
        <div *ngIf="email.errors?.['email']">
          Email must be valid.
        </div>
      </div>

    </div>


    <div class="col-12">
      <p class="mg-t-10 mg-b-5">Health system name<span ngbTooltip="required" class="text-danger">*</span></p>
      <input
        class="form-control" placeholder="Ascension, Mayo Clinic"
        [(ngModel)]="formRequestHealthSystem.name" name="name" #name="ngModel" required minlength="4"
      >

      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
        <div *ngIf="name.errors?.['required']">
          Health system name is required.
        </div>
        <div *ngIf="name.errors?.['minlength']">
          Health system name must be at least 4 characters long.
        </div>
      </div>

    </div>

    <div class="col-12">
      <p class="mg-t-10 mg-b-5">Website<span ngbTooltip="required" class="text-danger">*</span></p>
      <input
        class="form-control"
        [(ngModel)]="formRequestHealthSystem.website" name="website" #website="ngModel" required minlength="4"
      >

      <div *ngIf="website.invalid && (website.dirty || website.touched)" class="alert alert-danger">
        <div *ngIf="website.errors?.['required']">
          Website is required.
        </div>
        <div *ngIf="website.errors?.['minlength']">
          Website must be at least 4 characters long.
        </div>
      </div>
    </div>

    <div class="col-12">
      <p class="mg-t-10 mg-b-5">Street Address</p>
      <input
        class="form-control"
        [(ngModel)]="formRequestHealthSystem.street_address" name="street_address" #street_address="ngModel"
      >

    </div>

    <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
      <strong>Error</strong> {{errorMsg}}
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="!supportRequestForm.form.valid || loading" type="submit" class="btn btn-indigo">Submit</button>
    <button (click)="activeModal.dismiss('cancel')" type="button" class="btn btn-outline-light">Cancel</button>
  </div>

</form>


<ng-template #requestSuccess>
  <div class="tx-center pd-y-20 pd-x-20">
    <i class="far fa-check-square tx-100 tx-success lh-1 mg-t-20 d-inline-block"></i>
    <h4 class="tx-success mg-b-20">Success!</h4>
    <p class="mg-b-20 mg-x-20">Your request has been recorded. <br/>Thanks!</p>
    <button type="button" (click)="activeModal.close('success')" class="btn btn-success pd-x-25">Close</button>
  </div>
</ng-template>
